import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import App from "../App";
import Login from "../Pages/Auth/Login";
import ResetPassword from "../Pages/Auth/ResetPassword";
import PatientsList from "../Pages/Patients/List";
import AddPatient from "../Pages/Patients/Add";
import PatientsDetails from "../Pages/Patients/Details";
import OrderAdd from "../Pages/Orders/Add";
import OrdersList from "../Pages/Orders/List";
import OrdersDetails from "../Pages/Orders/Details";
import OrderEdit from "../Pages/Orders/Edit";
import DispensationsList from "../Pages/Dispensations/List";
import DispensationsMassiveList from "../Pages/Dispensations/MassivePlanList";
import PayersList from "../Pages/Payers/List";
import ProductsOptions from "../Pages/Products/ProductsOptions";
import DownloadPod from "../Pages/POD/DownloadPod";
import SendDispensations from "../Pages/Reports/SendDispensations";
import BlockedDispensations from "../Pages/Reports/BlockedDispensations";
import PlannedDispensations from "../Pages/Reports/PlannedDispensations";
import ReturnedDispensations from "../Pages/Reports/ReturnedDispensations";
import BillingDispensations from "../Pages/Reports/BillingDispensations";
import ExpiredPatients from "../Pages/Reports/ExpiredPatients";
import ExpiringPatients from "../Pages/Reports/ExpiringPatients";
import ResendDispensation from "../Pages/Settings/ResendDispensation";
import DiabeticExport from "../Pages/Diabetic/DiabeticExport";
import BillingList from "../Pages/Billing/List";
import AddFeeBilling from "../Pages/Billing/AddFeeBilling";
import AddQuantityBilling from "../Pages/Billing/AddQuantityBilling";
import BillingDetail from "../Pages/Billing/Detail";
import UpdatePassword from "../Pages/Auth/UpdatePassword";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<App/>}>
            <Route path={'/login'} element={<Login/>}></Route>
            <Route path={'/reset-password'} element={<ResetPassword/>}></Route>
            <Route path={'/update-password'} element={<UpdatePassword/>}></Route>
            <Route path={'/home'} element={<ProtectedRoute><PatientsList/></ProtectedRoute>}></Route>
            <Route path={'/lista-pazienti'} element={<ProtectedRoute><PatientsList/></ProtectedRoute>}></Route>
            <Route path={'/inserimento-pazienti'} index element={<ProtectedRoute isNotAllowed={['referente_aziendale', 'support']}><AddPatient/></ProtectedRoute>}></Route>
            <Route path={'/inserimento-ordine'} element={<ProtectedRoute isNotAllowed={['referente_aziendale', 'support']}><OrderAdd/></ProtectedRoute>}></Route>
            <Route path={'/dettaglio-pazienti/:id'} element={<ProtectedRoute><PatientsDetails/></ProtectedRoute>}></Route>
            <Route path={'/inserimento-ordine/:id'} element={<ProtectedRoute isNotAllowed={['referente_aziendale', 'support']}><OrderAdd/></ProtectedRoute>}></Route>
            <Route path={'/lista-ordini'} element={<ProtectedRoute><OrdersList/></ProtectedRoute>}></Route>
            <Route path={'/lista-dispensazioni'} element={<ProtectedRoute><DispensationsList/></ProtectedRoute>}></Route>
            <Route path={'/lista-pianificazione-dispensazioni'} element={<ProtectedRoute isNotAllowed={['data_entry', 'support', 'single_user', 'referente_aziendale']}><DispensationsMassiveList/></ProtectedRoute>}></Route>
            <Route path={'/dettaglio-ordini/:id'} element={<ProtectedRoute><OrdersDetails/></ProtectedRoute>}></Route>
            <Route path={'/modifica-ordine/:id'} element={<ProtectedRoute isNotAllowed={['support']}><OrderEdit/></ProtectedRoute>}></Route>
            <Route path={'/registro-payers'} element={<ProtectedRoute isNotAllowed={['referente_aziendale', 'single_user', 'data_entry', 'support']}><PayersList/></ProtectedRoute>}></Route>
            <Route path={'/gestione-prodotti'} element={<ProtectedRoute isNotAllowed={['data_entry', 'support', 'single_user', 'referente_aziendale']}><ProductsOptions/></ProtectedRoute>}></Route>
            <Route path={'/download-pod'} element={<ProtectedRoute isNotAllowed={['data_entry', 'support', 'single_user', 'referente_aziendale']}><DownloadPod/></ProtectedRoute>}></Route>
            <Route path={'/dispensazioni-inviate'} element={<ProtectedRoute><SendDispensations /></ProtectedRoute>}></Route>
            <Route path={'/dispensazioni-bloccate'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><BlockedDispensations /></ProtectedRoute>}></Route>
            <Route path={'/dispensazioni-programmate'} element={<ProtectedRoute><PlannedDispensations /></ProtectedRoute>}></Route>
            <Route path={'/dispensazioni-rese'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><ReturnedDispensations /></ProtectedRoute>}></Route>
            <Route path={'/fatturazione'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><BillingDispensations /></ProtectedRoute>}></Route>
            <Route path={'/pazienti-in-scadenza'} element={<ProtectedRoute><ExpiringPatients /></ProtectedRoute>}></Route>
            <Route path={'/pazienti-non-rinnovati'} element={<ProtectedRoute><ExpiredPatients /></ProtectedRoute>}></Route>
            <Route path={'/riprocessa-dispensazione'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale', 'support', 'superuser']}><ResendDispensation /></ProtectedRoute>}></Route>
            <Route path={'/export-lombardia'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale', 'support']}><DiabeticExport /></ProtectedRoute>}></Route>
            <Route path={'/lista-fatture'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><BillingList /></ProtectedRoute>}></Route>
            <Route path={'/aggiungi-fattura-canone'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><AddFeeBilling /></ProtectedRoute>}></Route>
            <Route path={'/aggiungi-fattura-quantita'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><AddQuantityBilling /></ProtectedRoute>}></Route>
            <Route path={'/dettaglio-fattura/:id'} element={<ProtectedRoute isNotAllowed={['data_entry', 'single_user', 'referente_aziendale']}><BillingDetail /></ProtectedRoute>}></Route>
        </Route>
    )
)



