//import json file
import cityData from '../assets/json/cities.json';
import axios from "axios";

//local autocomplete city
export const cityAutocomplete = (cityValue, setCity) => {
    // Filter the city data based on the input value
    const filteredCities = cityData.filter(city => city.city_name_ita.toLowerCase().startsWith(cityValue.toLowerCase()));

    filteredCities.sort((cityA, cityB) => {
        const lengthDifference = cityA.city_name_ita.length - cityB.city_name_ita.length;

        // If lengths are the same, sort alphabetically
        if (lengthDifference === 0) {
            return cityA.city_name_ita.localeCompare(cityB.city_name_ita);
        }

        return lengthDifference;
    });

    // Set the filtered cities in the state
    setCity(filteredCities);
}

//autocomplete address
export const addressAutocomplete = (address, city, setAddress) => {
    //remove comma to address
    address = address.replace(/,/g, '')

    axios.post(process.env.REACT_APP_BASE_URL + '/api/geo/v1/address', {
        address: address,
        city: city
    })
        .then((response) => {
            setAddress(response.data.data)
        })
        .catch((error) => {
            console.error(error)
        })
}

//get postal code
export const getPostalCode = (placeId, setPostalCode, setError, errorField) => {
    console.log(placeId)
    axios.post(process.env.REACT_APP_BASE_URL + '/api/geo/v1/postalcode', {
        'place_id': placeId
    })
    .then((response) => {
        setPostalCode(response.data.data)
    })
    .catch((error) => {
        console.error(error)
        setPostalCode('')
        setError({
            [errorField]: 'CAP non trovato per l\'indirizzo inserito.'
        })
    })
}

//validate postal code
export const validatePostalCode = (postalCode, city, setError, errorField) => {
    axios.post(process.env.REACT_APP_BASE_URL + '/api/geo/v1/validate_postal_code', {
        'city': city,
        'postal_code': postalCode
    })
    .then((response) => {
        setError({})
    })
    .catch((error) => {
        setError({
            [errorField]: 'Il CAP inserito non corrisponde al comune selezionato.'
        })
    })
}